import 'codemirror/addon/comment/comment'
import 'codemirror/mode/meta'
import 'codemirror/addon/mode/simple'
import 'codemirror/addon/mode/overlay'
import 'codemirror/addon/mode/multiplex'
import 'codemirror/addon/dialog/dialog'
import 'codemirror/addon/search/search'
import 'codemirror/addon/search/searchcursor'
import 'codemirror/addon/search/jump-to-line'
import 'codemirror/addon/edit/continuelist'
import {autoLoadMode, requireMode} from './codemirror/loadmode'
import CodeMirror from 'codemirror'

// Install custom mode loading hooks
CodeMirror.autoLoadMode = autoLoadMode
CodeMirror.requireMode = requireMode

CodeMirror.defineMode('conflict', (config, modeConfig) => {
  const conflictMode = {
    startState: () => {
      return {
        insideConflict: false,
      }
    },
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    token: (stream: any, state: any) => {
      if (stream.sol()) {
        if (stream.match(/^<<<<<<</)) {
          state.insideConflict = true
          stream.skipToEnd()
          return 'conflict-marker line-background-conflict-background'
        } else if (state.insideConflict && stream.match(/^=======/)) {
          stream.skipToEnd()
          return 'conflict-marker line-background-conflict-background'
        } else if (state.insideConflict && stream.match(/^>>>>>>>/)) {
          state.insideConflict = false
          stream.skipToEnd()
          return 'conflict-marker line-background-conflict-background'
        }
      }

      if (state.insideConflict) {
        stream.next()
        return 'line-background-conflict-background'
      }

      stream.next()
      return null
    },
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    blankLine: (state: any) => {
      if (state.insideConflict) {
        return 'line-background-conflict-background'
      }
      return null
    },
  }

  if (modeConfig.baseMode) {
    const baseMode = CodeMirror.getMode(config, modeConfig.baseMode)

    if (baseMode.name !== 'null') {
      return CodeMirror.overlayMode(baseMode, conflictMode, true)
    }
    const rawMode = CodeMirror.findModeByMIME(modeConfig.baseMode)
    // this seems like an awful hack, but I can't figure out how to get the editor/document to force a parse.
    CodeMirror.autoLoadMode(modeConfig.editor, rawMode.mode)
  }

  return conflictMode
})

CodeMirror.defineMIME('application/x-conflict', 'conflict')
// abap used to come from codemirror-contrib, but we can manually define it here instead
type CodeMirrorMimeType = (typeof CodeMirror.modeInfo)[0]
const abap: CodeMirrorMimeType & {contrib: boolean} = {
  name: 'ABAP',
  mime: 'text/abap',
  mode: 'abap',
  ext: ['abap'],
  contrib: true, // used to tell the loader to look in the /contrib directory
}
CodeMirror.modeInfo.push(abap)

export default CodeMirror
